






/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "crm-config",
  methods: {
    ...mapActions("crmSettingsModule", ["actListReferences"]),
  },
  mounted() {
    (this as any).actListReferences();
    (this as any).$parent.$parent.$parent.topBar = true;

    if (this.$vuetify.breakpoint.mobile == false) {
      (this as any).$parent.$parent.$parent.drawer = true;
    }
  },
  destroyed() {
    localStorage.removeItem("currentTab");
  },
});
